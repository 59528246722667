<template>
  <div>
    <div class="signup" v-if="openBooking == 'true'">
      <a href="/#/Examinee/FormTeam"><img src="/assets/img/SignUp.png" /></a>
    </div>
    <div class="signup" v-if="openExamSnSearching == 'true'">
      <a href="/#/Examinee/DataManage"><img src="/assets/img/SignUp3.png" /></a>
    </div>
    <div class="signup" v-if="isGradeQueryOpen == 'false' && isCampApplyOpen == 'true'">
      <a href="/#/Camp/Booking"><img src="/assets/img/SignUp2.png" /></a>
    </div>
    <div class="signup" v-if="isCampApplyOpen == 'false' && isGradeQueryOpen == 'true'">
      <a href="/#/HistoryGrade/Search"><img src="/assets/img/SignUp5.png" /></a>
    </div>
    <div class="signup" v-if="isCampApplyOpen == 'true' && isGradeQueryOpen == 'true'">
      <a href="/#/HistoryGrade/Search"><img src="/assets/img/SignUp4_1.png" /></a>
      <a href="/#/Camp/Booking"><img src="/assets/img/SignUp4_2.png" /></a>
    </div>
    <div class="signup" v-if="isGradePublicOpen == 'false'">
      <a href="/#/HistoryGrade/Search"><img src="/assets/img/SignUp5.png" /></a>
    </div>

    <footer class="footer-bg-white bg-light">
      <!-- Footer Color Bar -->
      <div class="color-bar">
        <div class="container-fluid">
          <div class="row">
            <div class="col color-bar bg-warning"></div>
            <div class="col color-bar bg-danger"></div>
            <div class="col color-bar bg-success"></div>
            <div class="col color-bar bg-info"></div>
            <div class="col color-bar bg-purple"></div>
            <div class="col color-bar bg-pink"></div>
            <div class="col color-bar bg-warning d-none d-md-block"></div>
            <div class="col color-bar bg-danger d-none d-md-block"></div>
            <div class="col color-bar bg-success d-none d-md-block"></div>
            <div class="col color-bar bg-info d-none d-md-block"></div>
            <div class="col color-bar bg-purple d-none d-md-block"></div>
            <div class="col color-bar bg-pink d-none d-md-block"></div>
          </div>
        </div>
      </div>

      <div class="py-8">
        <div class="container">
          <div class="row">
            <div class="col-sm-6 col-lg-3 col-xs-12">
              <a class="mb-6 d-block" href="https://www.knsh.com.tw/" target="_blank">
                <img
                  class="img-fluid d-inline-block w-90"
                  data-src="/assets/img/logo.jpg"
                  src="/assets/img/logo.jpg"
                />
              </a>
            </div>

            <div class="col-sm-6 col-lg-3 col-xs-12">
              <h4 class="section-title-sm font-weight-bold text-danger mb-2">服務時間</h4>
              <p class="mb-6">
                週一 ~ 週五 上午9：00 ~ 下午5：00<br />
                (午間12：00 ~ 13：45及例假日除外)
              </p>
              <h4 class="section-title-sm font-weight-bold text-danger mb-2">
                卓越盃競賽客服專線
              </h4>
              <p class="mb-6">(02) 8665 – 1745</p>
              <h4 class="section-title-sm font-weight-bold text-danger mb-2">
                線上課程客服專線
              </h4>
              <p class="mb-6">(02) 8665 – 1234</p>
              <h4
                v-if="isCampOpen == 'true'"
                class="section-title-sm font-weight-bold text-danger mb-2"
              >
                資優數學營客服專線
              </h4>
              <p v-if="isCampOpen == 'true'" class="mb-6">(02) 8665-1746</p>
            </div>

            <div class="col-sm-6 col-lg-2 col-xs-12">
              <ul class="list-unstyled">
                <li class="mb-4 fotbl">
                  <a href="/#/FAQ/EventInfo" @click="scrollToTop()">
                    <span class="bg-pink icon-header me-xl-2"
                      ><i aria-hidden="true" class="fa fa-question"></i
                    ></span>
                    常見問題
                  </a>
                </li>
                <li class="mb-4 fotbl">
                  <a
                    href="https://www.facebook.com/profile.php?id=100057468308948"
                    target="_blank"
                  >
                    <span class="bg-fb icon-header me-xl-2"
                      ><i aria-hidden="true" class="fab fa-facebook-f"></i
                    ></span>
                    卓越盃粉絲團
                  </a>
                </li>
                <li class="mb-4 fotbl">
                  <a href="/#/About/MemberPrivacy">
                    <span class="bg-info icon-header me-xl-2"
                      ><i aria-hidden="true" class="fa fa-users"></i
                    ></span>
                    會員使用條款
                  </a>
                </li>
                <li class="mb-4 fotbl">
                  <a href="/#/About/Privacy">
                    <span class="bg-success icon-header me-xl-2"
                      ><i aria-hidden="true" class="fa fa-check"></i
                    ></span>
                    隱私權政策
                  </a>
                </li>
              </ul>
            </div>

            <div class="col-sm-6 col-lg-2 col-xs-12 fotbl">
              <ul class="list-unstyled">
                <li class="mb-4">
                  <a href="https://line.me/ti/p/%40xwe6582i" target="_blank">
                    <span class="bg-success icon-header me-xl-2"
                      ><i aria-hidden="true" class="fab fa-line"></i
                    ></span>
                    加入好友
                  </a>
                </li>
                <li class="mb-4">
                  <div class="rounded-lg border-primary bg-white p-1">
                    <img
                      class="img-fluid"
                      data-src="/assets/img/qrcode.png"
                      src="/assets/img/qrcode.png"
                      alt="加入好友 QR CODE"
                    />
                  </div>
                </li>
              </ul>
            </div>

            <div class="col-sm-6 col-lg-2 col-xs-12 fotbl">
              <ul class="list-unstyled">
                <li class="mb-4">贊助單位</li>
                <li class="mb-4">
                  <a href="https://www.yourclass.com.tw/" target="_blank">
                    <img
                      class="rounded-lg border-primary d-block"
                      data-src="/assets/img/logo_yourclass.svg"
                      src="/assets/img/logo_yourclass.svg"
                      alt="康軒學習網"
                      width="140"
                  /></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <!-- Copy Right -->
      <div class="copyright">
        <div class="container">
          <div class="row py-4 align-items-center">
            <div class="col-sm-12 col-xs-12 order-1 order-md-0">
              <p class="text-center text-sm-left font-size-13 mb-0">
                康軒文教事業 著作權所有 © 2024 建議使用 Microsoft Edge 或 Chrome 1280 x
                720 以上解析度瀏覽本網站
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>

    <div>
      <a href="/#/FAQ/Important" class="back-to-top" id="back-to-top2" style="opacity: 1">
        <img
          class="rounded-lg border-primary d-block"
          data-src="/assets/img/fraud.png"
          src="/assets/img/fraud.png"
          alt="謹防詐騙"
        />
      </a>
    </div>
    <div class="">
      <a
        href="#pageTop"
        class="back-to-top"
        id="back-to-top"
        style="opacity: 1; visibility: visible"
      >
        <i class="fas fa-arrow-up" aria-hidden="true"></i>
      </a>
    </div>
    <!-- <div id="fb-root"></div> -->
  </div>
</template>

<script>
import $ from "jquery";
export default {
  data() {
    return {
      openBooking: true,
      openExamSnSearching: false,
      isCampOpen: false,
      isCampApplyOpen: false,
      isGradeQueryOpen: false,
      isGradePublicOpen: false,
      apiYearData: (data) => this.userRequest.post("KC/YearData", data),
      apiCampYearData: (data) => this.userRequest.post("KC/CampYearData", data),
    };
  },
  computed: {},
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.memberApplyTimeOver();
      this.getExamSnIsOpen();
      this.getGradeIsOpen();
      this.getCampIsOpen();
      this.getCampApplyIsOpen();

      setTimeout(function () {
        $("#fb-root").find(".container").css("bottom", "20%");
      }, 800);
    },
    scrollToTop() {
      if (this.$route.path === "/FAQ/EventInfo") {
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    },
    memberApplyTimeOver() {
      let json = {
        TYPE: "CHECK_APPLY_OVER",
      };
      this.FunctionToken(this.ApplyTimeOver, json);
    },
    ApplyTimeOver(data) {
      this.apiYearData(data)
        .then((res) => {
          let jsonRdata = JSON.parse(res.data);
          if (jsonRdata.Status) {
            this.openBooking = jsonRdata.Data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getExamSnIsOpen() {
      let json = {
        TYPE: "FRONT_EXAMROOM_ISOPEN",
      };
      this.FunctionToken(this.ExamSnIsOpen, json);
    },
    ExamSnIsOpen(data) {
      this.apiYearData(data)
        .then((res) => {
          let jsonRdata = JSON.parse(res.data);
          if (jsonRdata.Status) {
            this.openExamSnSearching = jsonRdata.Data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getGradeIsOpen() {
      try {
        let dataJSON = {
          TYPE: "FRONT_GRADE_ISOPEN",
        };

        let resDATA = await this.userRequest.post("KC/StudentHisGrade", dataJSON);
        let jsonRdata = JSON.parse(resDATA.data);
        if (jsonRdata.Status) {
          this.isGradeQueryOpen = jsonRdata.Data;
        }
      } catch (err) {
        console.log(err);
      }
    },
    async getGradePublicIsOpen() {
      try {
        let dataJSON = {
          TYPE: "FRONT_PUBLIC_ISOPEN",
        };

        let resDATA = await this.userRequest.post("KC/StudentHisGrade", dataJSON);
        let jsonRdata = JSON.parse(resDATA.data);
        if (jsonRdata.Status) {
          this.isGradePublicOpen = jsonRdata.Data;
        }
      } catch (err) {
        console.log(err);
      }
    },
    getCampIsOpen() {
      let json = {
        TYPE: "FRONT_CAMP_ISOPEN",
      };
      this.FunctionToken(this.CampIsOpen, json);
    },
    CampIsOpen(data) {
      this.apiCampYearData(data)
        .then((res) => {
          let jsonRdata = JSON.parse(res.data);
          if (jsonRdata.Status) {
            this.isCampOpen = jsonRdata.Data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getCampApplyIsOpen() {
      let json = {
        TYPE: "FRONT_APPLY_ISOPEN",
      };
      this.FunctionToken(this.CampApplyIsOpen, json);
    },
    CampApplyIsOpen(data) {
      this.apiCampYearData(data)
        .then((res) => {
          let jsonRdata = JSON.parse(res.data);
          if (jsonRdata.Status) {
            this.isCampApplyOpen = jsonRdata.Data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
